<template>
  <relatorio :headers="headersRel" />
</template>
<script>
  import Relatorio from './Relatorio.vue'

  export default {
    components: {
      Relatorio,
    },
    data () {
      return {
      }
    },
    computed: {
      headersRel () {
        return [
          { align: 'left', sortable: true, text: 'Produtor', value: 'produtor', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, text: 'Fazenda', value: 'fazenda', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: false, text: 'E-mail', value: 'email', width: 'auto', report: true, styleReport: { cellWidth: 65 } },
          { align: 'left', sortable: false, text: 'Telefone', value: 'telefone', width: 'auto', report: true, styleReport: { cellWidth: 30 } },
          { align: 'left', sortable: true, text: 'Endereço', value: 'endereco', width: 'auto', report: true },
          { align: 'left', sortable: false, text: 'Nº', value: 'numero', width: 'auto', report: true },
          { align: 'left', sortable: true, text: 'Bairro', value: 'bairro', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, text: 'Cep', value: 'cep', width: 'auto', report: true },
          { align: 'left', sortable: true, text: 'Cidade', value: 'cidade', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, text: 'UF', value: 'uf', width: 'auto', floatingfilter: true, report: true },
        ]
      },
    },
  }
</script>
