<template>
  <div class="content">
    <template>
      <v-card
        :key="$route.meta.relatorio"
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="!ready" />
        <div v-if="ready">
          <crud-list
            v-model="relatorio"
            :headers="headers"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            export-pdf-xlsx
            total-column
          >
            <template slot="datas">
              <v-col
                v-if="!tipo"
              >
                <data-field
                  v-model="data_inicial"
                  :label="'Data Inicial'"
                  :data-max="data_final"
                  @set="handleDataFilter()"
                />
              </v-col>
              <v-col
                v-if="!tipo"
              >
                <data-field
                  v-model="data_final"
                  :label="'Data Final'"
                  :data-min="data_inicial"
                  @set="handleDataFilter()"
                />
              </v-col>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import RelatorioStore, { BOOTSTRAP } from '@/store/modules/relatorio'
  import { relatorios } from '../../utils/constants'

  export default {
    props: {
      headers: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        data_inicial: 'from',
        data_final: 'to',
        tipo: this.$route.meta.relatorio === relatorios.produtoresFazendas,
      }
    },
    computed: {
      ...mapState('relatorio', ['ready', 'relatorio']),
      ...mapGetters(['hasPermission']),
      sortBy () {
        switch (this.$route.meta.relatorio) {
          case relatorios.vendasUnidadesNegocio: return ['unidade_negocio']
          case relatorios.vendasTecnicos: return ['tecnico']
          case relatorios.vendasProdutores: return ['produtor']
          case relatorios.desempenhoPromocoes: return ['unidade_negocio', 'promocao']
          case relatorios.produtosMaisVendidos: return ['percentual']
          case relatorios.produtoresSemCompra: return ['produtor', 'fazenda']
          case relatorios.produtoresFazendas: return ['produtor']
          case relatorios.acompanhamentoSemanalVendas: return ['periodo']
          default: return []
        }
      },
      sortDesc () {
        switch (this.$route.meta.relatorio) {
          case relatorios.vendasUnidadesNegocio: return [true]
          case relatorios.vendasTecnicos: return [true]
          case relatorios.vendasProdutores: return [true]
          case relatorios.desempenhoPromocoes: return [true, true]
          case relatorios.produtosMaisVendidos: return [true]
          case relatorios.produtoresSemCompra: return []
          case relatorios.produtoresFazendas: return []
          case relatorios.acompanhamentoSemanalVendas: return [true]
          default: return []
        }
      },
    },
    created () {
      if (!this.$store.hasModule('relatorio')) { this.$store.registerModule('relatorio', RelatorioStore) }
    },
    mounted () {
      if (this.$route.meta.relatorio !== relatorios.produtoresFazendas) {
        let data_i = new Date()
        data_i.setMonth(data_i.getMonth() - 3)
        data_i = data_i.toISOString().slice(0, 10)
        const data_f = new Date().toISOString().slice(0, 10)
        this.data_inicial = data_i
        this.data_final = data_f

        this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f, relatorio: this.$route.meta.relatorio, tipo: 0 })
      } else {
        this.BOOTSTRAP({ relatorio: this.$route.meta.relatorio, tipo: 1 })
      }
    },
    methods: {
      ...mapActions('relatorio', [BOOTSTRAP]),
      handleDataFilter () {
        this.BOOTSTRAP({ data_inicial: this.data_inicial, data_final: this.data_final, relatorio: this.$route.meta.relatorio, tipo: 0 })
      },
    },
  }
</script>
